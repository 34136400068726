import optionData from '@/assets/js/optionData';
import { fetchDataUploadPage, handleDataUploadStatus, fetchDataUploadDel } from '@/api/DDJ/data';
import { fetchCountryList } from '@/api/DDJ/country';
export default {
  components: {},
  data() {
    return {
      filter: {},
      options: {
        country: [],
        os: optionData.osOption,
        advertiser: [
          {
            label: 'formoreWorth',
            value: 'formoreWorth',
          },
        ],
        isUpdate: [
          {
            label: 'yes',
            value: 'yes',
          },
          {
            label: 'no',
            value: 'no',
          },
        ],
      },
      list: [],
      diallog: {
        updata: false,
      },
      loading: {
        list: false,
      },
      isUpdataPost: {
        id: '',
        isUpdate: '',
      },
      random: '',
      rules: {},
      pages: {
        pageNum: 1,
        pageSize: 20,
      },
      pagesTotal: 0,
      isShowPage: false,
    };
  },
  mounted() {
    this.getCountry();
    this.getList();
  },
  computed: {},
  methods: {
    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    // 获取列表
    getList(types) {
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      fetchDataUploadPage(query).then((res) => {
        let data = res.result;
        this.list = [];
        this.list = data.records;
        this.pagesTotal = data.total;
        this.loading.list = false;
        this.isShowPage = true;
      });
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: '/click/data/uploadList/edit',
        query: {
          id: id,
        },
      });
    },
    // 删除
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          console.log(id);
          fetchDataUploadDel({
            id: id,
          }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 更新
    updata(id, isUpdate) {
      this.isUpdataPost.id = id;
      this.isUpdataPost.isUpdate = isUpdate;
      this.diallog.updata = true;
    },

    // 保存子渠道配置
    submitUpdata() {
      handleDataUploadStatus(this.isUpdataPost).then((res) => {
        console.log(res);
        this.$message({
          message: '更新成功',
          type: 'success',
        });
        this.loading.btn = false;
        this.getList();
      });
      this.diallog.updata = false;
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
